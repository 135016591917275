import { useChannel } from 'ably/react';
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { setUrlCheckData } from '../redux-toolkit/reducer/ablyReducer';
import { updatePortalByOrgIdPortalId } from 'redux-toolkit/reducer/portalReducer';
import ablyMessages from 'utils/ablyMessages';
import * as Sentry from '@sentry/react';

export default function useAblyChannelHook() {
    const [message, setAblyMessage] = useState({});

    const dispatch = useDispatch();

    const { currentPortal, command, messageData } = useSelector(
        ({ portal, user, ably }) => ({
            currentPortal: portal?.portal,
            isSignOutUser: user.isSignOutUser,
            command: ably?.message,
            messageData: ably?.messageData,
        }),
        isEqual,
    );

    const { channel } =
        useChannel('portals', (message) => {
            if (message?.name === currentPortal?.portal_id) {
                setAblyMessage(message);
            }
        }) || {};

    useEffect(() => {
        return () => {
            if (channel) {
                // eslint-disable-next-line no-console
                console.log('unsubscribing from channel');
                channel.unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (message) {
            try {
                const command = message?.data?.message || '';
                // @ts-expect-error
                const commandData = message?.data?.data || {};
                if (command === ablyMessages.troubleshooting.selfDiagnoseResult) {
                    const transformedData = Object.entries(commandData).map(([key, value]) => ({
                        host: key,
                        // @ts-expect-error
                        alive: value?.alive,
                        reason: value?.reason,
                        lastCheckedStatusTimestamp: Date.now(),
                    }));
                    dispatch(setUrlCheckData(transformedData));
                    dispatch(
                        updatePortalByOrgIdPortalId({
                            updatedPortal: {
                                portalURLStatus: transformedData,
                            },
                            portalId: currentPortal?.portal_id,
                            orgId: currentPortal?.org_id,
                        }),
                    );
                }
            } catch (error) {
                Sentry.captureException(error);
                // eslint-disable-next-line no-console
                console.error(error);
            }
        }
    }, [message, dispatch]);

    const publishMessage = useCallback(
        (message, messageData) => {
            try {
                if (channel) {
                    channel.publish(message, messageData);
                } else {
                    // eslint-disable-next-line no-console
                    console.warn('Channel not ready for publishing. Ensure subscription is complete.');
                }
            } catch (error) {
                Sentry.captureException(error);
                // eslint-disable-next-line no-console
                console.error(error);
            }
        },
        [channel],
    );

    useEffect(() => {
        if (command) {
            publishMessage(currentPortal?.portal_id, {
                message: command,
                data: messageData,
            });
        }
    }, [command, publishMessage, messageData, currentPortal?.portal_id]);

    return { message, channel };
}
