import { AblyProvider, ChannelProvider } from 'ably/react';
import useAblyClient from '../hooks/useAblyClient';
import { createContext, useContext, useMemo } from 'react';
import { AblyStateProvider } from './AblyStateProvider';
import { useSelector } from 'react-redux';

import isEqual from 'lodash/isEqual';
const AblyClientContext = createContext(null);
function AblyClientProvider({ children }) {
    const ablyClient = useAblyClient();

    const { loggedInUserStore } = useSelector(
        ({ organization, user }) => ({
            isSignOutUser: user.isSignOutUser,
            loggedInUserStore: user.loggedInUser,
        }),
        isEqual,
    );

    const isRenderAblyComponent = useMemo(() => {
        return loggedInUserStore?.email && ablyClient;
    }, [loggedInUserStore?.email, ablyClient]);

    return isRenderAblyComponent ? (
        <AblyClientContext.Provider>
            <AblyProvider client={ablyClient}>
                <ChannelProvider
                    channelName="portals"
                    options={{ modes: ['PRESENCE_SUBSCRIBE', 'PUBLISH', 'SUBSCRIBE'] }}
                >
                    <AblyStateProvider>{children}</AblyStateProvider>
                </ChannelProvider>
            </AblyProvider>
        </AblyClientContext.Provider>
    ) : (
        children
    );
}

function useAblyClientContext() {
    const context = useContext(AblyClientContext);
    if (!context) {
        throw new Error('useAblyStateContext must be used within the AblyStateProvider');
    }
    return context;
}

export { AblyClientProvider, useAblyClientContext };
