import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCameraPreviewError } from 'redux-toolkit/reducer/ioConfigReducer';
import {} from 'redux-toolkit/reducer/portalReducer';
import ablyMessages from 'utils/ablyMessages';

export default function useMultiCamConfigResponse({ message }) {
    const dispatch = useDispatch();

    useEffect(() => {
        const command = message?.data?.message || '';
        const commandData = message?.data?.data || {};
        if (command === ablyMessages.camera.multiCamConfigResponse) {
            if (commandData.error) {
                // dispatch(setCameraPreviewError({ error: false, message: '' }));
                dispatch(setCameraPreviewError({ error: true, message: commandData.message }));
            }
        }
    }, [message, dispatch]);
}
