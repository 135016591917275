import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { onServiceWorkerUpdate } from './utils';

import { icons } from './assets/icons';
import { AppServiceUpdater, ErrorBoundary } from 'Components';
import { I18nextProvider } from 'react-i18next';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux-toolkit/store/configure';
import i18n from './i18n';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'portals-admin-frontend@' + process.env.REACT_APP_VERSION,
    integrations: [
        new Integrations.BrowserTracing(),
        // This will give ability to see correct path in the dev tools
        new Sentry.Integrations.Breadcrumbs({
            console: false,
        }),
    ],
    tracesSampleRate: 1.0,
});

React.icons = icons;
const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </PersistGate>
            </Provider>
        </I18nextProvider>
        <AppServiceUpdater />
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//     onUpdate: onServiceWorkerUpdate,
// });
