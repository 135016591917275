import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { PORTAL_STATES } from 'utils';

export default function usePortalStatus() {
    const { currentPortal, presenceData } = useSelector(
        ({ portal, ably }) => ({
            currentPortal: portal?.portal,
            presenceData: ably?.presenceData || [],
        }),
        isEqual,
    );

    const portalStatus = useMemo(() => {
        const presencePortal = presenceData.find(({ clientId }) => clientId === currentPortal?.portal_id);
        const presencePortalStatus = presencePortal?.data?.status || presencePortal?.data?.data?.status;

        if (presencePortalStatus) return presencePortalStatus;

        return currentPortal?.finalPortalStatus === PORTAL_STATES.ACTIVE
            ? PORTAL_STATES.OFFLINE
            : currentPortal?.finalPortalStatus || PORTAL_STATES.OFFLINE;
    }, [presenceData, currentPortal]);

    return portalStatus;
}
