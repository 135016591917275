// Built-in
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// External libraries
import _ from 'lodash';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { PortalModel } from '../../redux-toolkit/model/portalModel';
// Internal project-specific
import { RootState } from '../../redux-toolkit/reducer';
import {
    agentManualDownloadLink,
    regenerateRegistrationKeyOfPortal,
    setAgentUpdateStatusEmpty,
    setRemoveRegeneratedKey,
    updatePortalByOrgIdPortalId,
} from '../../redux-toolkit/reducer/portalReducer';
import {
    PORTAL_STATES,
    TOAST_FADEOUT,
    LATEST_VERSION_NOT_INSTALLED,
    LOCAL_ENV_PORTAL_AGENT,
    LATEST_VERSION_DOWNLOADED,
} from '../../utils';
import { setToast } from '../../redux-toolkit/reducer/toastReducer';
import When from '../when/When';
import PortalStatusChip from 'Components/StatusChip/PortalStatusChip';
import { CSwitch } from '@coreui/react';
// import useAutoSaveNodeHook from 'hooks/useAutoSaveNodeHook';
import { getFeatureObj } from 'utils/getFeatureObj';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-feather';
import usePortalStatus from 'hooks/usePortalStatus';

const PortalStatusCard = (props: any) => {
    const dispatch = useDispatch();
    const params: { orgId: ''; portalId: '' } = useParams();
    const methods = useFormContext();
    const portalStatus = usePortalStatus();

    const [showRegenerateKeyBtn, setShowRegenerateKeyBtn] = useState(false);
    const [isNewVersionAvail, setIsNewVersionAvail] = useState(false);
    const [currentPAVersion, setCurrentPAVersion] = useState('');
    // const { savingNodeData } = useAutoSaveNodeHook({ props, params });
    // The useRef Hook allows you to persist data between renders
    const prevPAVersion = useRef<string>('');

    const updateLoader = JSON.parse(localStorage.getItem('updateLoader') ?? '{}');
    const [loader, setLoader] = useState(
        JSON.parse(localStorage.getItem('updateLoader') ?? '{}')[params.portalId] ?? false,
    );

    const regenerateRegistrationKey = () => {
        dispatch(regenerateRegistrationKeyOfPortal({ orgId: params.orgId, portalId: params.portalId }));
        setShowRegenerateKeyBtn(false);
    };

    const {
        regeneratedKey,
        agentManualDLink,
        getPortalAgentUpdateStatus,
        getPortalAgentUpdateStatusError,
        getPortalAgentUpdateStatusLoading,
    } = useSelector(
        ({ portal }: RootState) => ({
            regeneratedKey: portal?.regeneratedKey || {},
            agentManualDLink: portal.agentManualDLink,
            getPortalAgentUpdateStatus: portal.getPortalAgentUpdateStatus,
            getPortalAgentUpdateStatusLoading: portal.getPortalAgentUpdateStatusLoading,
            getPortalAgentUpdateStatusError: portal.getPortalAgentUpdateStatusError,
        }),
        _.isEqual,
    );

    const currentPortal = useMemo<PortalModel>(() => {
        return props.currentPortal;
    }, [props.currentPortal]);

    const { currentOrg } = useSelector(
        ({ organization }: RootState) => ({
            currentOrg: organization.organization,
        }),
        _.isEqual,
    );

    const featureFlags = getFeatureObj(currentOrg as any) as { [key: string]: boolean };

    const isManualDownloadLinkReady =
        agentManualDLink?.hasOwnProperty('objectUrl') && agentManualDLink?.hasOwnProperty('expiresAt');

    const agentUpdateInstalled = () => {
        setLoader(false);
        setIsNewVersionAvail(false);
        localStorage.setItem('updateLoader', JSON.stringify({ ...updateLoader, [`${params.portalId}`]: false }));
        props.agentUpdateInstalled();
    };

    const setToastContent = (toastContent: string) => props.setToastContent(toastContent);

    const isLocalEnvPortal = (version: string) => version !== LOCAL_ENV_PORTAL_AGENT;

    const isLatestVersionNotInstalled = (comparison: number) => comparison === LATEST_VERSION_NOT_INSTALLED;

    const isLatestVersionDownloaded = (comparison: number) => comparison === LATEST_VERSION_DOWNLOADED;

    const setPAUpdateAvailable = (isLatestYetToInstall: boolean, installedVersion: string) =>
        setIsNewVersionAvail(isLatestYetToInstall && isLocalEnvPortal(installedVersion));

    // const setPAUpdateDownloaded = (isLatestYetToInstall: boolean, hasDownloaded: boolean) =>
    //     setIsUpdateDownloaded(isLatestYetToInstall && hasDownloaded);

    /**
   * This will return:

    0: version strings are equal
    1: version currentVersion is greater than latestVersion
    -1: version latestVersion is greater than currentVersion
   */
    const compareVersions = (currentVersion: string, latestVersion: string): number =>
        currentVersion.localeCompare(latestVersion, undefined, { numeric: true, sensitivity: 'base' });

    const isUpdatedDownloadedOnPA = useMemo(() => {
        /*
        If the currentPortal is empty, it means that API polling data has not come yet.
        In this case, we check if the currentPortal has a downloaded version and if it is the latest version.
        If it is, we set isUpdatedDownloadedOnPA to true.
      */
        if (currentPortal && Object.keys(currentPortal).length === 0) {
            return currentPortal?.agentUpdateDownloadedVersion && currentPortal?.latestAgentVersionDetails?.version
                ? isLatestVersionDownloaded(
                      compareVersions(
                          currentPortal?.agentUpdateDownloadedVersion,
                          currentPortal?.latestAgentVersionDetails?.version,
                      ),
                  )
                : currentPortal?.isAgentUpdateDownloaded;
        }
        /*
        If the currentPortal has a downloaded version, it means that the API polling data has come.
        In this case, we check if the downloaded version is the latest version.
        If it is, we set isUpdatedDownloadedOnPA to true.
      */
        return currentPortal?.agentUpdateDownloadedVersion && currentPortal?.latestAgentVersionDetails?.version
            ? isLatestVersionDownloaded(
                  compareVersions(
                      currentPortal?.agentUpdateDownloadedVersion,
                      currentPortal?.latestAgentVersionDetails?.version,
                  ),
              )
            : currentPortal?.isAgentUpdateDownloaded;
    }, [currentPortal]);

    const setUpdateStates = useCallback(
        (current: string | null, latest: string | null) => {
            if (!latest || !current) {
                setIsNewVersionAvail(false);
                return;
            }
            const isLatestYetToInstall = isLatestVersionNotInstalled(compareVersions(current, latest));
            setPAUpdateAvailable(isLatestYetToInstall, current);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        if (!currentPortal) return;
        const { agentVersion: currentPortalVersion, latestAgentVersionDetails } = currentPortal;
        if (latestAgentVersionDetails?.version) {
            const { version: latestVersionNumber } = latestAgentVersionDetails;
            setUpdateStates(currentPortalVersion, latestVersionNumber);
        }
    }, [currentPortal, setUpdateStates]);

    const updateAgentVersion = () => {
        if (
            currentPortal &&
            currentPortal.finalPortalStatus === PORTAL_STATES.ACTIVE &&
            portalStatus !== PORTAL_STATES.ONLINE
        ) {
            setToastContent('You can only apply this update when the portal is online and available.');
        } else {
            setLoader(true);
            localStorage.setItem('updateLoader', JSON.stringify({ ...updateLoader, [`${params.portalId}`]: true }));
            dispatch(setToast({ type: '', message: '', msgHeader: '', show: false }));
            props.updateAgentVersion();
        }
    };

    const manualAgentDownloadLinkDiv = (isOnAddNodePage: boolean) => (
        <div className="body3-secondary no-list-avail dark">
            {!isOnAddNodePage
                ? `Alternatively, download the newer version from these links to update manually or by using your organization’s configuration management tools.`
                : `Using these links, download the PortalAgent to the portal computer. Install and launch it. Finally, enter the registration key displayed here once you have clicked on ‘Register’ on this page.`}

            {/* Hiding as per the requrement   */}
            {/* <br /> */}

            {/* <span className="body3-secondary">
                <span>MacOS:&ensp;&ensp;</span>
                <span className="black">
                    <a href={Object.values(agentManualDLink?.objectUrl['mac'][3])[0]}>
                        <u>DMG</u>
                    </a>
                    &ensp;&ensp;
                    <a href={Object.values(agentManualDLink?.objectUrl['mac'][2])[0]}>
                        <u>DMG(ARM64)</u>
                    </a>
                    &ensp;&ensp;
                </span>
            </span> */}

            <br />

            <span className="body3-secondary">
                <span>Windows:&ensp;&ensp;</span>
                <span className="black">
                    <a href={Object.values(agentManualDLink?.objectUrl['windows'][0])[0]}>
                        <u>EXE</u>
                    </a>
                </span>
                <span style={{ marginLeft: '10px' }}>{agentManualDLink?.version}</span>
            </span>

            {/* <span>
      <span>MacOS: </span>
      <span className="d-flex align-items-start justify-content-between">
        <a href={Object.values(agentManualDLink.objectUrl['mac'][3])[0]}><u>DMG</u></a>
        <a href={Object.values(agentManualDLink.objectUrl['mac'][2])[0]}><u>DMG(ARM64)</u></a>
        <a href={Object.values(agentManualDLink.objectUrl['mac'][1])[0]}><u>ZIP</u></a>
        <a href={Object.values(agentManualDLink.objectUrl['mac'][0])[0]}><u>ZIP(ARM64)</u></a>
      </span>
    </span>

    <span>
      <span>Windows: </span>
      <span className="d-flex align-items-start justify-content-between">
        <a href={Object.values(agentManualDLink.objectUrl['windows'][0])[0]}><u>EXE</u></a>
      </span>
    </span> */}
        </div>
    );

    const abcdCasesForAutoUpdate = () => {
        if (
            currentPortal &&
            currentPortal?.finalPortalStatus === PORTAL_STATES.ACTIVE &&
            'latestAgentVersionDetails' in currentPortal &&
            currentPortal?.latestAgentVersionDetails?.version &&
            !loader &&
            isNewVersionAvail
        ) {
            // case A : New version is available but portal is offline
            if (
                !isUpdatedDownloadedOnPA &&
                portalStatus !== PORTAL_STATES.ONLINE &&
                portalStatus !== PORTAL_STATES.IN_MAINTENANCE
            ) {
                return (
                    <div className="body3-secondary no-list-avail">
                        {`v${currentPortal.latestAgentVersionDetails.version} - A newer version is available and will automatically be downloaded when the portal is online and available.`}
                    </div>
                );
            }

            // case B : A new version is available and is being downloaded on portal
            else if (
                !isUpdatedDownloadedOnPA &&
                (portalStatus === PORTAL_STATES.ONLINE || portalStatus === PORTAL_STATES.IN_MAINTENANCE)
            ) {
                return (
                    <div className="body3-secondary no-list-avail">
                        {`v${currentPortal.latestAgentVersionDetails.version} - A newer version is being downloaded. Check for updates here to apply it.`}
                    </div>
                );
            }

            // case C : New version is available and has been downloaded but portal is offline
            else if (
                isUpdatedDownloadedOnPA &&
                portalStatus !== PORTAL_STATES.ONLINE &&
                portalStatus !== PORTAL_STATES.IN_MAINTENANCE
            ) {
                return (
                    <div className="body3-secondary no-list-avail">
                        {`v${currentPortal.latestAgentVersionDetails.version} - A newer version is available and has been downloaded. You can apply it when the portal is online and available, or in maintenance.`}
                    </div>
                );
            }

            // case D : New version is available and downloaded and portal is also online
            else if (
                isUpdatedDownloadedOnPA &&
                (portalStatus === PORTAL_STATES.ONLINE || portalStatus === PORTAL_STATES.IN_MAINTENANCE)
            ) {
                return (
                    <div className="body3-secondary no-list-avail green">
                        {`v${currentPortal.latestAgentVersionDetails.version} - A newer version is available and has been downloaded. Click the “Update” button to apply it.`}
                    </div>
                );
            }
        }
    };

    useEffect(() => {
        if (currentPortal?.agentVersion) {
            setCurrentPAVersion(currentPortal?.agentVersion);
        }
    }, [currentPortal]);

    useEffect(() => {
        const comparison = compareVersions(currentPAVersion, prevPAVersion.current);
        if (loader) {
            if (comparison === 1) {
                agentUpdateInstalled();
            } else {
                setLoader(false);
                localStorage.setItem(
                    'updateLoader',
                    JSON.stringify({ ...updateLoader, [`${params.portalId}`]: false }),
                );
                // dispatch(
                //     setToast({
                //         type: 'error',
                //         message: 'PortalAgent update failed',
                //         msgHeader: 'Error!',
                //         show: true,
                //     }),
                // );
            }
            prevPAVersion.current = currentPAVersion;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPAVersion]);

    // useEffect(() => {
    //     let interval: NodeJS.Timeout | undefined;
    //     if (loader) {
    //         interval = setInterval(() => {
    //             dispatch(getAgentUpdateStatus({ orgId: params.orgId, portalId: params.portalId }));
    //         }, 3000);
    //     }
    //     return () => clearInterval(interval);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [loader]);

    useEffect(() => {
        if (
            !getPortalAgentUpdateStatusLoading &&
            !getPortalAgentUpdateStatusError &&
            getPortalAgentUpdateStatus &&
            loader &&
            'updateInProgress' in getPortalAgentUpdateStatus &&
            !getPortalAgentUpdateStatus.updateInProgress &&
            'status' in getPortalAgentUpdateStatus &&
            getPortalAgentUpdateStatus.status &&
            'message' in getPortalAgentUpdateStatus &&
            getPortalAgentUpdateStatus.message
        ) {
            const { message, status } = getPortalAgentUpdateStatus;
            if (status === 'success') {
                dispatch(setToast({ type: status, message: message, msgHeader: 'Success!', show: true }));
            } else {
                dispatch(setToast({ type: status, message: message, msgHeader: 'Error!', show: true }));
            }
            setTimeout(() => {
                dispatch(setToast({ type: '', message: '', msgHeader: '', show: false }));
                dispatch(setAgentUpdateStatusEmpty());
            }, TOAST_FADEOUT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPortalAgentUpdateStatus, getPortalAgentUpdateStatusLoading, getPortalAgentUpdateStatusError, loader]);

    useEffect(() => {
        if (currentPortal?.latestAgentVersionDetails?.version && currentPortal?.agentVersion) {
            const latestVersionNumber: string = currentPortal?.latestAgentVersionDetails.version;
            const currentPortalVersion: string = currentPortal.agentVersion;
            const notDevMachine = currentPortalVersion !== '0.0.0';
            const comparison = compareVersions(currentPortalVersion, latestVersionNumber);
            if (latestVersionNumber && currentPortalVersion && comparison === -1 && notDevMachine) {
                setIsNewVersionAvail(true);
            } else {
                setIsNewVersionAvail(false);
            }
        } else {
            setIsNewVersionAvail(false);
        }
    }, [currentPortal, isUpdatedDownloadedOnPA]);

    useEffect(() => {
        if (isNewVersionAvail || currentPortal === null) {
            dispatch(agentManualDownloadLink());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewVersionAvail, currentPortal]);

    const updateInProgress = useMemo(() => {
        return getPortalAgentUpdateStatus.updateInProgress;
    }, [getPortalAgentUpdateStatus.updateInProgress]);

    useEffect(() => {
        // let timeoutId: NodeJS.Timeout | undefined;
        if (updateInProgress) {
            setLoader(true);
        }

        // return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateInProgress]);

    useEffect(() => {
        if (getPortalAgentUpdateStatus?.status === 'error' || getPortalAgentUpdateStatus?.status === 'success') {
            // console.log('(getPortalAgentUpdateStatus status', getPortalAgentUpdateStatus?.status);
            setLoader(false);
        }
    }, [getPortalAgentUpdateStatus?.status]);

    useEffect(() => {
        if (!loader) {
            setLoader(false);
            localStorage.setItem('updateLoader', JSON.stringify({ ...updateLoader, [`${params.portalId}`]: false }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loader]);

    useEffect(() => {
        const isExpired = (key: any) => (key?.registrationKeyExpiration ?? 0) < new Date().getTime();
        if (regeneratedKey && Object.keys(regeneratedKey).length > 0) {
            setShowRegenerateKeyBtn(isExpired(regeneratedKey));
        } else if (currentPortal && 'registrationKeyExpiration' in currentPortal) {
            setShowRegenerateKeyBtn(isExpired(currentPortal));
        }
    }, [currentPortal, regeneratedKey]);

    const isPortalInConnectionOrOffline = () => {
        if (portalStatus && portalStatus === PORTAL_STATES.IN_CALL) {
            return true;
        }

        return false;
    };

    const isEligibleForManualDownload = useMemo(() => {
        const { currentPortal } = props;

        if (!currentPortal) {
            return isManualDownloadLinkReady;
        }

        const { latestAgentVersionDetails, finalPortalStatus } = currentPortal;

        const isVersionAvailable = latestAgentVersionDetails && latestAgentVersionDetails.version;

        const isPortalInactive =
            finalPortalStatus !== PORTAL_STATES.ACTIVE &&
            finalPortalStatus !== PORTAL_STATES.ARCHIVED &&
            isVersionAvailable &&
            !loader &&
            !isNewVersionAvail &&
            !isUpdatedDownloadedOnPA &&
            (portalStatus === PORTAL_STATES.OFFLINE || portalStatus === PORTAL_STATES.PENDING);

        return isManualDownloadLinkReady && isPortalInactive;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPortal, loader, isNewVersionAvail, isUpdatedDownloadedOnPA, isManualDownloadLinkReady]);

    const handleUnRegisterPortal = () => {
        const tempPortal = {} as any;
        tempPortal.updatedPortal = {
            isUnRegistered: true,
            type: currentPortal?.type,
        };
        tempPortal.portalId = currentPortal?.portal_id;
        tempPortal.orgId = currentPortal?.org_id;
        dispatch(setRemoveRegeneratedKey());
        setShowRegenerateKeyBtn(false);
        dispatch(updatePortalByOrgIdPortalId(tempPortal));
    };

    return (
        <div className="status-card">
            <When isTrue={currentPortal !== null}>
                <div className="body1-primary portal-status-chip align-items-center">
                    Status
                    <PortalStatusChip />
                    <When
                        isTrue={currentPortal && featureFlags.featureNetworkHealth && !isPortalInConnectionOrOffline()}
                    >
                        <div
                            className={`pill-container ml-auto ${
                                portalStatus === PORTAL_STATES.DISCONNECTED || portalStatus === PORTAL_STATES.IN_CALL
                                    ? 'disabled'
                                    : ''
                            }`}
                        >
                            <div className="pill-text mr-2 align-self-center body3-secondary">Maintenance</div>
                            {/* <CSwitch
                                onChange={(e) => updateMaintenanceStatus(e)}
                                defaultChecked={currentPortal?.inMaintenance}
                                className="custom-pill"
                                color="primary"
                                shape="pill"
                                disabled={false}
                                name="inMaintenance"
                            /> */}

                            <Controller
                                name="portalForm.inMaintenance"
                                control={methods?.control} // Assuming `methods` is the form context from `useFormContext` or `useForm`
                                render={({ field }) => (
                                    <CSwitch
                                        onChange={(e: any) => field.onChange(e.target.checked)} // Pass checked value to the form
                                        checked={field.value} // Bind the switch state to the form value
                                        className="custom-pill"
                                        color="primary"
                                        shape="pill"
                                        disabled={false}
                                    />
                                )}
                            />
                        </div>
                    </When>
                </div>
            </When>
            <When isTrue={currentPortal}>
                <div className="body1-primary">
                    <span className="reg-no-select">Node ID </span>
                    <span className="portalId black">{currentPortal?.portal_id ? currentPortal.portal_id : '-'}</span>
                </div>
            </When>
            <When isTrue={currentPortal === null}>
                <div className="body1-primary">
                    Registration key <span className="black">XXX-XXX-XXX</span>
                </div>
            </When>

            {currentPortal &&
            (portalStatus === PORTAL_STATES.DISCONNECTED ||
                portalStatus === PORTAL_STATES.PENDING ||
                ('registrationKey' in currentPortal &&
                    'registrationKeyExpiration' in currentPortal &&
                    currentPortal?.finalPortalStatus !== PORTAL_STATES.ACTIVE)) &&
            currentPortal.finalPortalStatus !== PORTAL_STATES.ARCHIVED ? (
                <div className="body1-primary">
                    <span className="reg-no-select">Registration key</span>
                    <span className="black">
                        {regeneratedKey?.registrationKey && (
                            <span className={`body1-primary registration-key ${showRegenerateKeyBtn ? 'strike' : ''}`}>
                                {regeneratedKey?.registrationKey}
                            </span>
                        )}
                        {regeneratedKey && !('registrationKey' in regeneratedKey) && currentPortal?.registrationKey && (
                            <span className={`body1-primary registration-key ${showRegenerateKeyBtn ? 'strike' : ''}`}>
                                {currentPortal?.registrationKey}
                            </span>
                        )}
                        <When
                            isTrue={
                                regeneratedKey &&
                                !regeneratedKey.hasOwnProperty('registrationKey') &&
                                (portalStatus === PORTAL_STATES.DISCONNECTED ||
                                    portalStatus === PORTAL_STATES.PENDING ||
                                    ('registrationKey' in currentPortal &&
                                        'registrationKeyExpiration' in currentPortal &&
                                        currentPortal?.finalPortalStatus !== PORTAL_STATES.ACTIVE)) &&
                                currentPortal?.finalPortalStatus !== PORTAL_STATES.ARCHIVED &&
                                currentPortal.registrationKey === null
                            }
                        >
                            <span className={`body1-primary registration-key`}>XXX-XXX-XXX</span>
                        </When>
                        <When isTrue={showRegenerateKeyBtn}>
                            <span className="regenerate-key ml-2" onClick={regenerateRegistrationKey}>
                                Re-generate Key
                            </span>
                        </When>
                    </span>
                </div>
            ) : (
                <></>
            )}
            <When isTrue={currentPortal}>
                <div className="body1-primary">
                    <span className="reg-no-select">Created </span>
                    <span className="portalCreationDate black">
                        {currentPortal?.createdOn
                            ? `${new Date(currentPortal?.createdOn).toLocaleDateString('en-US', {
                                  month: '2-digit',
                                  day: '2-digit',
                                  year: 'numeric',
                              })}`
                            : '01/25/2020'}
                    </span>
                </div>
            </When>
            <When isTrue={currentPortal === null}>
                <div className="body1-primary">
                    <span className="reg-no-select">Created </span>
                    <span className="portalCreationDate black">
                        {new Date().toLocaleDateString('en-US', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                        })}
                    </span>
                </div>
            </When>
            <When isTrue={currentPortal && !('agentVersion' in currentPortal)}>
                <div className="body1-primary d-flex version-container">
                    <span className="reg-no-select">Software Version </span>
                    <span className="portal-version black">
                        {currentPortal?.finalPortalStatus !== PORTAL_STATES.ACTIVE ? 'NA' : '-'}
                    </span>
                </div>
            </When>
            <When isTrue={currentPortal && currentPortal.agentVersion}>
                <div className="body1-primary d-flex version-container">
                    <span className="reg-no-select">Software Version </span>
                    {currentPortal && currentPortal.agentVersion ? (
                        <span className="portal-version black">v{currentPortal.agentVersion}</span>
                    ) : (
                        '-'
                    )}
                    <div className="btn-text-container update-container">
                        <When isTrue={loader}>
                            <span className="spinner-container">
                                <Spinner animation="border" variant="light">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </span>
                        </When>
                        <When isTrue={!loader && isNewVersionAvail}>
                            <div
                                className={`btn-circle-new-cont ${
                                    isUpdatedDownloadedOnPA &&
                                    (portalStatus === PORTAL_STATES.ONLINE ||
                                        portalStatus === PORTAL_STATES.IN_MAINTENANCE)
                                        ? 'enabled'
                                        : 'disabled'
                                }`}
                                onClick={updateAgentVersion}
                            >
                                <button className="btn-circle1 sm4">
                                    <i className="icon-update font-19px" />
                                </button>
                                <div className="text-cont body3-secondary">Update</div>
                            </div>
                        </When>
                        <When isTrue={!loader && !isNewVersionAvail}>
                            <div className={`btn-circle-new-cont`} style={{ cursor: 'default' }}>
                                <div className="text-cont body3-secondary black">Up-to-date</div>
                            </div>
                        </When>
                    </div>
                </div>
            </When>

            {abcdCasesForAutoUpdate()}
            {currentPortal &&
                currentPortal.finalPortalStatus === PORTAL_STATES.ACTIVE &&
                'latestAgentVersionDetails' in currentPortal &&
                currentPortal?.latestAgentVersionDetails?.version &&
                !loader &&
                isNewVersionAvail &&
                isManualDownloadLinkReady &&
                manualAgentDownloadLinkDiv(false)}

            {isEligibleForManualDownload && manualAgentDownloadLinkDiv(true)}

            <When
                isTrue={
                    currentPortal !== null &&
                    portalStatus !== PORTAL_STATES.UNREGISTERED &&
                    portalStatus !== PORTAL_STATES.ARCHIVED &&
                    portalStatus !== PORTAL_STATES.PENDING &&
                    props.selectedTab === 0
                }
            >
                <div className="body1-primary portal-unregister body3-secondary" style={{ cursor: 'default' }}>
                    <Link size={20} />
                    <div className="link-button" onClick={handleUnRegisterPortal}>
                        Un-register portal
                    </div>
                </div>
            </When>
        </div>
    );
};

export default React.memo(PortalStatusCard);
