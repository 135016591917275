const ablyMessages = {
    camera: {
        multiCamConfig: 'multi-cam-config',
        multiCamConfigResponse: 'multi-cam-config-response',
    },
    portalAuthentication: {
        securityOptionActivated: 'security-option-activated',
        securityOptionDeactivated: 'security-option-deactivated',
    },
    troubleshooting: {
        selfDiagnoseCheck: 'check-self-diagnose',
        selfDiagnoseResult: 'check-self-diagnose-result',
    },
    healthCheck: {
        hourlyCallQualityTest: 'test-hourly-call-quality',
        callQualityTest: 'test-call-quality',
        fetchPortalCallQualityData: 'fetch-portal-call-quality-data',
    },
    version: {
        installAgentUpdates: 'install-agent-updates',
        portalVersionUpgrade: 'portal-version-upgrade',
    },
    screenshots: {
        screenshotTaken: 'SCREENSHOT_TAKEN',
        screenshotError: 'SCREENSHOT_TAKEN_ERROR',
    },
    logs: {
        logUploadSuccess: 'log-uploaded-successfully',
    },
};

export default ablyMessages;
