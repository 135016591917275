import usePortalStatus from 'hooks/usePortalStatus';
import { statusMap } from 'utils/Utils';

function PortalStatusChip() {
    const portalStatus = usePortalStatus();
    if (!portalStatus) return null;

    // Get status from the map
    const status = statusMap[portalStatus];

    // Render other statuses if the portal is activated or approved
    if (status) {
        return <div className={`status-text ${status.className} body5-secondary`}>{status.text}</div>;
    }

    return null;
}

export default PortalStatusChip;
